import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-development',
  templateUrl: './web-development.component.html',
  styleUrls: ['./web-development.component.scss']
})
export class WebDevelopmentComponent implements OnInit {

  selectedTab = 1;

  constructor() {
  }

  ngOnInit() {
  }


  selectTab(number) {
    this.selectedTab = number;
  }

}
