import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-business-finance',
    templateUrl: './business-finance.component.html',
    styleUrls: ['./business-finance.component.scss']
})
export class BusinessFinanceComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
