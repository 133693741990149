import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-agile',
    templateUrl: './agile.component.html',
    styleUrls: ['./agile.component.scss']
})
export class AgileComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
