import {Component, OnInit} from '@angular/core';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private meta: Meta) {
    // Twitter Cards
    this.meta.updateTag({name: 'twitter:creator', content: '@SYMBTechnology'});
    this.meta.updateTag({name: 'twitter:title', content: 'SYMB Technologies - About'});
    this.meta.updateTag({
      name: 'twitter:description',
      content: 'SYMB Technologies helps agencies, startups, SMEs &amp; large enterprises turn their ideas into reality by delivering solutions turn-key results on time and on budget.'
    });
    this.meta.updateTag({name: 'twitter:image', content: 'https://www.symbtechnologies.com/symb-home-hero.6bd69d8965a05e338796.jpg'});

    // Facebook Cards
    this.meta.updateTag({property: 'og:url', content: 'http://www.symbtechnologies.com/about'});
    this.meta.updateTag({property: 'og:title', content: 'SYMB Technologies - About'});
    this.meta.updateTag({
      property: 'og:description',
      content: 'SYMB Technologies helps agencies, startups, SMEs &amp; large enterprises turn their ideas into reality by delivering solutions turn-key results on time and on budget.'
    });
    this.meta.updateTag({property: 'og:image', content: 'https://www.symbtechnologies.com/symb-home-hero.6bd69d8965a05e338796.jpg'});
    this.meta.updateTag({property: 'og:updated_time', content: '2019-07-07'});

    // Linkedin Cards
    this.meta.updateTag({property: 'og:publish_date', content: '2019-07-07'});

  }

  ngOnInit() {
  }

}
