import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HealthcareComponent} from './industries/healthcare/healthcare.component';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {HomeComponent} from './home/home.component';
import {CompanyComponent} from './company/company.component';
import {TeamComponent} from './team/team.component';
import {ProductsComponent} from './products/products.component';
import {CareerComponent} from './career/career.component';
import {ContactComponent} from './contact/contact.component';
import {AboutComponent} from './about/about.component';
import {TechnologyConsultingComponent} from './technology-consulting/technology-consulting.component';
import { ApplicationDevelopmentComponent} from './services/adm/application-development/application-development.component';
import {ApplicationOptimizationComponent} from './application-optimization/application-optimization.component';
import {DigitalMarketingComponent} from './services/digital/digital-marketing/digital-marketing.component';
import {ContinuousDeliveryComponent} from './continuous-delivery/continuous-delivery.component';
import {DigitalStrategyComponent} from './digital-strategy/digital-strategy.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {MediaAndTechnologyComponent} from './industries/media-and-technology/media-and-technology.component';
import {EducationComponent} from './industries/education/education.component';
import {BusinessFinanceComponent} from './industries/business-finance/business-finance.component';
import {EnergyComponent} from './industries/energy/energy.component';
import {NonprofitNdGovernmentComponent} from './industries/nonprofit-nd-government/nonprofit-nd-government.component';
import {RetailComponent} from './industries/retail/retail.component';
import {ItConsultingComponent} from './services/it-consulting/it-consulting.component';
import {CloudComponent} from './services/digital/cloud/cloud.component';
import {DataAnalyticsComponent} from './services/digital/data-analytics/data-analytics.component';
import {DigitalCommerceComponent} from './services/digital/digital-commerce/digital-commerce.component';
import {MobilityComponent} from './services/digital/mobility/mobility.component';
import {UserExperienceDesignComponent} from './services/digital/user-experience-design/user-experience-design.component';
import {CyberSecurityComponent} from './services/digital/cyber-security/cyber-security.component';
import {IotComponent} from './services/emerging-tech/iot/iot.component';
import {BlockchainComponent} from './services/emerging-tech/blockchain/blockchain.component';
import {AiMlComponent} from './services/emerging-tech/ai-ml/ai-ml.component';
import {ArVrComponent} from './services/emerging-tech/ar-vr/ar-vr.component';
import {SupportAndMaintenanceComponent} from './services/adm/support-and-maintenance/support-and-maintenance.component';
import {QualityAssuranceComponent} from './services/adm/quality-assurance/quality-assurance.component';
import {AgileComponent} from './services/adm/agile/agile.component';
import {DevOpsComponent} from './services/adm/dev-ops/dev-ops.component';
import {CaseStudiesComponent} from './case-studies/case-studies.component';
import { CaseStudySectionComponent } from './case-study-section/case-study-section.component';
import { EngageSymbComponent } from './engage-symb/engage-symb.component';
import { ServicesVerticalNavPanelComponent } from './services-vertical-nav-panel/services-vertical-nav-panel.component';
import { WordpressWebsiteDevelopmentServiceComponent } from './landing-pages/wordpress-website-development-service/wordpress-website-development-service.component';
import {FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { LandingPageSuccessComponent } from './landing-pages/wordpress-query-landing-page/landing-page-success.component';
import { WebDevelopmentComponent } from './services/adm/web-development/web-development.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CompanyComponent,
    TeamComponent,
    ProductsComponent,
    CareerComponent,
    ContactComponent,
    AboutComponent,
    TechnologyConsultingComponent,
    ApplicationDevelopmentComponent,
    ApplicationOptimizationComponent,
    DigitalMarketingComponent,
    ContinuousDeliveryComponent,
    DigitalStrategyComponent,
    MediaAndTechnologyComponent,
    HealthcareComponent,
    EducationComponent,
    BusinessFinanceComponent,
    EnergyComponent,
    NonprofitNdGovernmentComponent,
    RetailComponent,
    ItConsultingComponent,
    CloudComponent,
    DataAnalyticsComponent,
    DigitalCommerceComponent,
    MobilityComponent,
    UserExperienceDesignComponent,
    CyberSecurityComponent,
    IotComponent,
    BlockchainComponent,
    AiMlComponent,
    ArVrComponent,
    SupportAndMaintenanceComponent,
    QualityAssuranceComponent,
    AgileComponent,
    DevOpsComponent,
    CaseStudiesComponent,
    CaseStudySectionComponent,
    EngageSymbComponent,
    ServicesVerticalNavPanelComponent,
    WordpressWebsiteDevelopmentServiceComponent,
    LandingPageSuccessComponent,
    WebDevelopmentComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'my-app'}),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
