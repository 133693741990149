import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-data-analytics',
  templateUrl: './data-analytics.component.html',
  styleUrls: ['./data-analytics.component.scss']
})
export class DataAnalyticsComponent implements OnInit {

  constructor() {
  }

  services = [];

  ngOnInit() {
    this.services.push({
      title: 'Next-Gen Analytics',
      imageUrl: '../../../assets/images/services/digital/next-gen.jpg',
      content: 'Use next-gen analytics to power a data-driven organisation'
    });
    this.services.push({
      title: 'Data Visualization',
      imageUrl: '../../../assets/images/services/digital/data-visualization.png',
      content: 'Use advanced data visualization for better insights as compared to traditional enterprise reporting'
    });
    this.services.push({
      title: 'Business Intelligence',
      imageUrl: '../../../assets/images/services/digital/bi.jpg',
      content: 'Enable data driven decision- making for better outcomes'
    });
    this.services.push({
      title: 'Big data',
      imageUrl: '../../../assets/images/services/digital/big-data.jpg',
      content: 'Harness big data analytics to drive better business decision'
    });
    this.services.push({
      title: 'Enterprise Data Management',
      imageUrl: '../../../assets/images/services/digital/enterprise-data-management.jpg',
      content: 'Improve operational efficiency and reduce reporting mistakes'
    });
    this.services.push({
      title: 'Informed Intelligence',
      imageUrl: '../../../assets/images/services/digital/informed-intelligence.jpg',
      content: 'Apply decision science to resolve your business issues'
    });
    this.services.push({
      title: 'Frameworks',
      imageUrl: '../../../assets/images/services/digital/analytics-frameworks.jpg',
      content: 'Create data mapping framework which includes policies, practices and procedures to manage the big data life cycle of an enterprise'
    });
    this.services.push({
      title: 'COE',
      imageUrl: '../../assets/images/services/digital/foundation.jpg',
      content: 'Build a data analytics foundation for a long term'
    });
  }

}
