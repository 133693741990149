import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-wordpress-website-development-service',
  templateUrl: './wordpress-website-development-service.component.html',
  styleUrls: ['./wordpress-website-development-service.component.scss']
})
export class WordpressWebsiteDevelopmentServiceComponent implements OnInit {


  model = {
    name: '',
    email: '',
    phone: '',
    message: '',
    source: 'LANDING_PAGE',
    sourceRef: 'wordpress-website-development-services'
  };

  myForm: FormGroup;

  constructor(private meta: Meta, private titleService: Title, private http: HttpClient, private router: Router) {

    var metaTitle = 'Wordpress website development | Create WP Mobile App, Plugins, Themes';
    var metaDescription = 'Hire experts to develop a website for your business? Now drop an email at info@symbtechnologies.com or, Call at +91-9910406128';

    this.titleService.setTitle(metaTitle);

    this.meta.updateTag({
      name: 'title',
      content: metaTitle
    });
    this.meta.updateTag({
      name: 'description',
      content: metaDescription
    });

    // Twitter Cards
    this.meta.updateTag({name: 'twitter:creator', content: '@SYMBTechnology'});
    this.meta.updateTag({name: 'twitter:title', content: 'SYMB Technologies - About'});
    this.meta.updateTag({
      name: 'twitter:description',
      content: metaDescription
    });
    this.meta.updateTag({name: 'twitter:image', content: 'https://www.symbtechnologies.com/symb-home-hero.6bd69d8965a05e338796.jpg'});

    // Facebook Cards
    this.meta.updateTag({property: 'og:url', content: 'http://www.symbtechnologies.com/about'});
    this.meta.updateTag({property: 'og:title', content: metaTitle});
    this.meta.updateTag({
      property: 'og:description',
      content: metaDescription
    });
    this.meta.updateTag({property: 'og:image', content: 'https://www.symbtechnologies.com/symb-home-hero.6bd69d8965a05e338796.jpg'});
    this.meta.updateTag({property: 'og:updated_time', content: '2019-07-07'});

    // Linkedin Cards
    this.meta.updateTag({property: 'og:publish_date', content: '2019-07-07'});

  }

  services = [];
  traits = [];

  ngOnInit() {
    this.services.push({
      title: 'Create mobile ready/responsive wordpress websites',
      imageUrl: '../../../assets/images/services/digital/next-gen.jpg',
      content: 'Our websites developed websites are fully responsive for mobiles, tablets, desktop screens. We keep in mind to provide the best user experience to the visitor for the website.'
    });
    this.services.push({
      title: 'Convert wordpress website to mobile apps',
      imageUrl: '../../../assets/images/services/digital/data-visualization.png',
      content: 'With users using mobile app everyday, we have made it very easy to convert your website into a mobile app. All you are supposed to do is share us your website details, we give you a mobile app back with a very quick turn-around time of a few weeks.'
    });
    this.services.push({
      title: 'Create beautiful wordpress themes',
      imageUrl: '../../../assets/images/services/digital/bi.jpg',
      content: 'Building beautiful themes to suit you business objectives has been our forte. We have built more than 50 themes for various kinds of businesses tailor made to their business offerings.'
    });
    this.services.push({
      title: 'Create custom wordpress plugins',
      imageUrl: '../../../assets/images/services/digital/big-data.jpg',
      content: 'Plugins are great modules which help to leverage the power of wordpress. We built custom plugins as per your requirements which help you get custom features on the wordpress website.'
    });
    this.services.push({
      title: 'Create single/one-page wordpress responsive websites',
      imageUrl: '../../../assets/images/services/digital/enterprise-data-management.jpg',
      content: 'The one-page website is suitable for small enterprises. We deliver a consistent layout throughout the website by using our developed single-page templates. '
    });
    this.services.push({
      title: 'Wordpress website with ecommerce using woocommerce',
      imageUrl: '../../../assets/images/services/digital/informed-intelligence.jpg',
      content: 'Using WooCommerce we customized e-commerce development over the wordpress website. All you gotta do is tell us your industry & we come back to you with a possible layouts & flow for your ecommerce website.'
    });
    this.services.push({
      title: 'Landing page creation for lead generation online',
      imageUrl: '../../../assets/images/services/digital/analytics-frameworks.jpg',
      content: 'We help you design & optimize landing pages for lead generation activities for any of your marketing campaigns. Our landing pages come with an admin module for which you can access the information about all the leads captured.'
    });


    this.traits.push({
      title: 'The price that Suits Anyone',
      imageUrl: '../../../assets/images/landing-pages/icons/money-bag.svg'
    });
    this.traits.push({
      title: 'A pool of WordPress Talents',
      imageUrl: '../../../assets/images/landing-pages/icons/success.svg'
    });
    this.traits.push({
      title: 'Remain Quality Focused',
      imageUrl: '../../../assets/images/landing-pages/icons/smartphone.svg'
    });

    this.myForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9.@]*[@][a-z0-9.@]*')]),
      phone: new FormControl(''),
      message: new FormControl('')
    });
  }


  submitQuery(form: FormGroup) {
    this.model.name = form.value.name;
    this.model.phone = form.value.phone;
    this.model.email = form.value.email;
    this.model.message = form.value.message;
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        // 'Accept': 'application/json'
      })
    };
    this.http.post('https://apireq.symbtechnologies.com/api/lead', this.model, httpOptions).subscribe(res => {
      if (res.hasOwnProperty('lead')) {
        this.router.navigate(['success', 'wordpress-website-development-services']);
      }
    });
  }

}
