import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isIndustriesMenuShown = false;
  isServicesMenuShown = false;
  isMobileMenuShown = false;

  constructor(private router: Router) {
    router.events.subscribe(val => {
      this.hideMenu();
    });
  }

  ngOnInit() {
  }

  handleMobileMenu() {
    if (this.isMobileMenuShown) {
      this.hideMenu();
    } else {
      this.isMobileMenuShown = !this.isMobileMenuShown;
    }
  }

  hideMenu() {
    this.isIndustriesMenuShown = false;
    this.isServicesMenuShown = false;
    this.isMobileMenuShown = false;
  }

  handleIndustriesMenu() {
    if (!this.isIndustriesMenuShown) {
      this.hideMenu();
      this.isIndustriesMenuShown = true;
    } else {
      this.isIndustriesMenuShown = false;
    }
  }

  handleServicesMenu() {
    if (!this.isServicesMenuShown) {
      this.hideMenu();
      this.isServicesMenuShown = true;
    } else {
      this.isServicesMenuShown = false;
    }
  }
}
