import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Element} from '@angular/compiler';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss']
})
export class CaseStudiesComponent implements OnInit {

  @ViewChild('link') link: ElementRef<HTMLElement>;

  isFormShown = false;
  email = '';
  name = '';
  phone = '';
  path = '';


  isEmailError = false;
  isNameError = false;

  constructor() {
  }

  ngOnInit() {
  }

  openForm(number) {
    this.isFormShown = true;
    if (number == 1) {
      this.path = '../../../assets/files/industries/case-one.pdf';
    } else if (number == 2) {
      this.path = '../../../assets/files/industries/case-two.pdf';
    } else if (number == 3) {
      this.path = '../../../assets/files/industries/case-three.pdf';
    } else {

    }
  }


  close() {
    this.isFormShown = false;
    this.name = '';
    this.email = '';
    this.phone = '';
    this.isNameError = false;
    this.isEmailError = false;
  }

  validateRequest() {
    if (this.name == '') {
      this.isNameError = true;
      return false;
    } else if ( this.email == '') {
      this.isEmailError = true;
      this.isNameError = false;
      return false;
    } else {
      this.isEmailError = false;
      return true;
    }
  }

  submit() {
    if (this.validateRequest()) {
      const el: HTMLElement = this.link.nativeElement;
      console.log(el);
      el.click();
      this.close();
    }
  }

}
