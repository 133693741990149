import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-engage-symb',
  templateUrl: './engage-symb.component.html',
  styleUrls: ['./engage-symb.component.scss']
})
export class EngageSymbComponent implements OnInit {

  constructor() { }

  @Input() for: string;

  ngOnInit() {
  }

}
