import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  readURL(event: any): void {
    if (event && event.target && event.target.files) {
      this.uploadImage(event.target.files.item(0));
    }
  }

  uploadImage(file: File): void {
    // // this.homeService.uploadCompanyLogo(`org_logo_${(new Date()).getTime()}.${file.name.split('.').pop()}`, file).subscribe(reply => {
    // //   const response = reply as any;
    //   this.model.OrganizationBasicDetails.company_logo = response.mediaLink;
    // }, error => {
    // });
  }


}
