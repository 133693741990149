import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page-success',
  templateUrl: './landing-page-success.component.html',
  styleUrls: ['./landing-page-success.component.scss']
})
export class LandingPageSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
