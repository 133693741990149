import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-ar-vr',
    templateUrl: './ar-vr.component.html',
    styleUrls: ['./ar-vr.component.scss']
})
export class ArVrComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
