import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {TeamComponent} from './team/team.component';
import {ProductsComponent} from './products/products.component';
import {CareerComponent} from './career/career.component';
import {ContactComponent} from './contact/contact.component';
import {AboutComponent} from './about/about.component';
import {TechnologyConsultingComponent} from './technology-consulting/technology-consulting.component';
import {ApplicationDevelopmentComponent} from './services/adm/application-development/application-development.component';
import {ApplicationOptimizationComponent} from './application-optimization/application-optimization.component';
import {DigitalMarketingComponent} from './services/digital/digital-marketing/digital-marketing.component';
import {ContinuousDeliveryComponent} from './continuous-delivery/continuous-delivery.component';
import {DigitalStrategyComponent} from './digital-strategy/digital-strategy.component';

import {HealthcareComponent} from './industries/healthcare/healthcare.component';
import {MediaAndTechnologyComponent} from './industries/media-and-technology/media-and-technology.component';
import {EducationComponent} from './industries/education/education.component';
import {BusinessFinanceComponent} from './industries/business-finance/business-finance.component';
import {EnergyComponent} from './industries/energy/energy.component';
import {RetailComponent} from './industries/retail/retail.component';
import {NonprofitNdGovernmentComponent} from './industries/nonprofit-nd-government/nonprofit-nd-government.component';
import {ItConsultingComponent} from './services/it-consulting/it-consulting.component';
import {CloudComponent} from './services/digital/cloud/cloud.component';
import {CyberSecurityComponent} from './services/digital/cyber-security/cyber-security.component';
import {DataAnalyticsComponent} from './services/digital/data-analytics/data-analytics.component';
import {DigitalCommerceComponent} from './services/digital/digital-commerce/digital-commerce.component';
import {MobilityComponent} from './services/digital/mobility/mobility.component';
import {UserExperienceDesignComponent} from './services/digital/user-experience-design/user-experience-design.component';
import {IotComponent} from './services/emerging-tech/iot/iot.component';
import {BlockchainComponent} from './services/emerging-tech/blockchain/blockchain.component';
import {AiMlComponent} from './services/emerging-tech/ai-ml/ai-ml.component';
import {ArVrComponent} from './services/emerging-tech/ar-vr/ar-vr.component';
import {SupportAndMaintenanceComponent} from './services/adm/support-and-maintenance/support-and-maintenance.component';
import {QualityAssuranceComponent} from './services/adm/quality-assurance/quality-assurance.component';
import {AgileComponent} from './services/adm/agile/agile.component';
import {DevOpsComponent} from './services/adm/dev-ops/dev-ops.component';
import {CaseStudiesComponent} from './case-studies/case-studies.component';
import {WordpressWebsiteDevelopmentServiceComponent} from './landing-pages/wordpress-website-development-service/wordpress-website-development-service.component';
import {LandingPageSuccessComponent} from './landing-pages/wordpress-query-landing-page/landing-page-success.component';
import {WebDevelopmentComponent} from './services/adm/web-development/web-development.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'about.php',
    component: AboutComponent,
  },
  {
    path: 'services/technology-consulting',
    component: TechnologyConsultingComponent,
  },
  {
    path: 'Technology-Consulting',
    component: TechnologyConsultingComponent,
  },
  {
    path: 'services/application-development',
    component: ApplicationDevelopmentComponent,
  },
  {
    path: 'App-Development',
    component: ApplicationDevelopmentComponent,
  },
  {
    path: 'App-Optimization',
    component: ApplicationOptimizationComponent,
  },
  {
    path: 'services/application-optimization',
    component: ApplicationOptimizationComponent,
  },
  {
    path: 'Digital-Marketing',
    component: DigitalMarketingComponent,
  },
  {
    path: 'services/digital-marketing',
    component: DigitalMarketingComponent,
  },
  {
    path: 'Continous-Development',
    component: ContinuousDeliveryComponent,
  },
  {
    path: 'services/continuous-delivery',
    component: ContinuousDeliveryComponent,
  },
  {
    path: 'Digital-Strategy',
    component: DigitalStrategyComponent,
  },
  {
    path: 'services/digital-strategy',
    component: DigitalStrategyComponent,
  },
  {
    path: 'about/team',
    component: TeamComponent,
  },
  {
    path: 'products',
    component: ProductsComponent,
  },
  {
    path: 'careers',
    component: CareerComponent,
  },
  {
    path: 'contact-us',
    component: ContactComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'industries/media-and-technology',
    component: MediaAndTechnologyComponent
  },
  {
    path: 'industries/healthcare',
    component: HealthcareComponent
  },
  {
    path: 'industries/education',
    component: EducationComponent
  },
  {
    path: 'industries/business-finance',
    component: BusinessFinanceComponent
  },
  {
    path: 'industries/energy',
    component: EnergyComponent
  },
  {
    path: 'industries/retail',
    component: RetailComponent
  },
  {
    path: 'industries/nonprofit-and-government',
    component: NonprofitNdGovernmentComponent
  },
  {
    path: 'services/it-consulting',
    component: ItConsultingComponent
  },
  {
    path: 'services/digital/cloud',
    component: CloudComponent
  },
  {
    path: 'services/digital/cyber-security',
    component: CyberSecurityComponent
  },
  {
    path: 'services/digital/data-analytics',
    component: DataAnalyticsComponent
  },
  {
    path: 'services/digital/digital-commerce',
    component: DigitalCommerceComponent
  },
  {
    path: 'services/digital/digital-marketing',
    component: DigitalMarketingComponent
  },
  {
    path: 'services/digital/mobility',
    component: MobilityComponent
  },
  {
    path: 'services/digital/user-experience-design',
    component: UserExperienceDesignComponent
  },
  {
    path: 'services/emerging-tech/iot',
    component: IotComponent
  },
  {
    path: 'services/emerging-tech/blockchain',
    component: BlockchainComponent
  },
  {
    path: 'services/emerging-tech/ai-ml',
    component: AiMlComponent
  },
  {
    path: 'services/emerging-tech/ar-vr',
    component: ArVrComponent
  },
  {
    path: 'services/application-development-and-management/application-development',
    component: ApplicationDevelopmentComponent
  },
  {
    path: 'services/application-development-and-management/web-development',
    component: WebDevelopmentComponent
  },
  {
    path: 'services/application-development-and-management/maintenance-and-support',
    component: SupportAndMaintenanceComponent
  },
  {
    path: 'services/application-development-and-management/quality-assurance',
    component: QualityAssuranceComponent
  },
  {
    path: 'services/application-development-and-management/agile',
    component: AgileComponent
  },
  {
    path: 'services/application-development-and-management/devops',
    component: DevOpsComponent
  },
  {
    path: 'wordpress-website-development-services',
    component: WordpressWebsiteDevelopmentServiceComponent
  },
  {
    path: 'case-studies',
    component: CaseStudiesComponent
  },
  {
    path: 'success/wordpress-website-development-services',
    component: LandingPageSuccessComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
