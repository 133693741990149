import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-support-and-maintenance',
    templateUrl: './support-and-maintenance.component.html',
    styleUrls: ['./support-and-maintenance.component.scss']
})
export class SupportAndMaintenanceComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
