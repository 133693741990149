import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-application-development',
  templateUrl: './application-development.component.html',
  styleUrls: ['./application-development.component.scss']
})
export class ApplicationDevelopmentComponent implements OnInit {

  selectedTab = 1;

  constructor() {
  }

  ngOnInit() {
  }


  selectTab(number) {
    this.selectedTab = number;
  }

}
